import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Config from "./config";
import Service from "./libs/services";
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.prototype.Config = Config;
Vue.prototype.Service = Service;
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$axios = axios
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
