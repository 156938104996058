import Vue from 'vue'
import Router from 'vue-router'
import Goods from '@/views/Goods/index.vue'
import Notes from '@/views/Note/index.vue'
import Topics from '@/views/Topic/index.vue'
import About from '@/views/About/index.vue'
Vue.use(Router)
const router = new Router({
    mode: 'history',
    routes: [
        {
            redirect: '/goods',
            path: '/',
        },
        {
            path: '/goods',
            name: 'Goods',
            component: Goods
        },
        {
            path: '/notes',
            name: 'Notes',
            component: Notes
        },
        {
            path: '/topics',
            name: 'topics',
            component: Topics
        },
        {
            path: '/about',
            name: 'About',
            component: About
        }
    ]
})

export default router
