<template>
  <div class="vod-video" :class="{full: isFull}" :id="rid">
  </div>
</template>

<script>
let index = 0
export default {
  name: 'VodVideo',
  props: {
    isFull: {
      type: Boolean
    },
    url: {
      type: String
    },
    cover: {
      type: String
    }
  },
  data () {
    return {
      rid: ''
    }
  },
  created () {
    index++
    this.rid = `vod-video-${index}`
  },
  watch: {
    url () {
      if (this.player) {
        const state = this.player.getStatus()
        if (state !== 'error' && state !== 'init') {
          this.player.loadByUrl(this.url)
        }
      }
    }
  },
  mounted () {
    this.player = new window.Aliplayer({
      id: this.rid,
      width: '100%',
      height: '100%',
      rePlay: true,
      cover: this.cover
    })
    if (this.url) {
      this.player.loadByUrl(this.url)
    }
  },
  beforeDestroy () {
    this.player && this.player.dispose()
  }
}
</script>

<style lang="stylus">
.vod-video.prism-player
  .prism-cover
    background-size contain
    background-repeat no-repeat
    background-position center
</style>
