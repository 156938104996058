// import storage from '../libs/storage'

export const setAuth = (state, auth) => {
  if (auth && state.auth && auth.access_token === state.auth.access_token) return
  state.auth = auth
  // storage.session.set('auth', auth)
}

export const logout = state => {
  setAuth(state, null)
}