/* eslint-disable dot-notation */
import _ from 'lodash'
import axios from 'axios'
import store from '../../store'
import Vue from 'vue'
import md5 from 'js-md5'
import { getKey } from '@/utils'

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(config => {
  const kv = {}
  let bodyCnt = ''
  if (config.data) {
    if (config.data instanceof FormData) {
      config.data.forEach((val, key) => {
        if (val && !(val instanceof File)) {
          kv[key] = val
        }
      })
    } else {
      bodyCnt = JSON.stringify(config.data)
    }
  }
  if (config.params) {
    for (const key in config.params) {
      kv[key] = config.params[key]
    }
  }
  kv['deviceId'] = config.headers['deviceId'] || ''
  kv['deviceInfo'] = config.headers['deviceInfo'] || ''
  kv['channel'] = config.headers['channel'] || ''
  const seed = new Date().getTime() + ''
  config.headers['s' + 'e' + 'e' + 'd'] = seed
  kv['s' + 'e' + 'e' + 'd'] = seed

  const keys = _.sortBy(_.keys(kv))
  let str = config.url.replace(/http(s)?:\/\/[^/]+/, '')
  for (const key of keys) {
    str += key + (_.isNil(kv[key]) ? '' : kv[key])
  }
  str += bodyCnt
  str += getKey()
  config.headers['sign'] = md5(str).toUpperCase()
  return config
})

axios.defaults.withCredentials = false
const Api = {}
const methodsWithParams = ['get', 'head', 'delete']

const toastLoginStateErr = _.debounce(resp => {
  const statusCode = +resp.data.statusCode
  let inApp = !!(window.android || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.h5IsReady))
  const err = [401, 402, 499].indexOf(statusCode) > -1 ? (inApp ? '请重新登录' : '请登录') : (resp.data.errorMessage || '请重新登录')
  Vue.prototype.$toast.default(err)
  store.commit('logout')
  if (inApp && Vue.prototype.invokeAppMethod) {
    Vue.prototype.invokeAppMethod('toLogin')
  }
}, 300)

const toastErr = _.debounce(err => {
  Vue.prototype.$toast.default(err)
}, 300)

// 返回 {promise, cancelHandler(用于取消请求)}
Api.http = function (url, method, data, params, ignoreCache, responseType, noToken, silence) {
  if (!data) {
    data = {}
  } else {
    data = _.cloneDeep(data)
  }
  const opts = {}
  let isQueryMethod = false
  if (_.indexOf(methodsWithParams, method) > -1) {
    isQueryMethod = true
    // 如果传入了body值，则 data 表示 body 值，params 表示 URL参数
    if (params) {
      opts.params = params
      opts.data = data
    } else {
      opts.params = data
      !ignoreCache && (opts.params.t = new Date().getTime())
    }
  } else if (params) {
    opts.params = params
  }
  opts.headers = {}
  if (store.state.auth && store.state.auth.access_token && !noToken) {
    opts.headers.Authorization = 'bearer ' + store.state.auth.access_token
  }
  if (window.deviceInfo) {
    opts.headers.deviceInfo = window.deviceInfo
    opts.headers.deviceId = window.deviceId
    opts.headers.channel = window.channel
  }
  let cancelHandler
  opts.cancelToken = new axios.CancelToken(cancel => {
    cancelHandler = cancel
  })
  if (responseType) {
    opts.responseType = responseType
  }
  const queryPage = window.location.href
  return {
    promise: (isQueryMethod ? axios[method](url, opts) : axios[method](url, data, opts)).then(resp => {
      if (responseType) {
        return resp
      }
      const statusCode = +resp.data.statusCode
      if (statusCode === 200) {
        return resp.data.data
      } else if ([401, 402, 499, 603, 604, 605, 606, 607].indexOf(statusCode) > -1) {
        toastLoginStateErr(resp)
      } else if (resp.data.errorMessage) {
        !silence && toastErr(resp.data.errorMessage)
      }
      return Promise.reject(resp)
    }, resp => {
      let message = ''
      if (resp.message === 'Network Error' && queryPage === window.location.href) message = '网络错误，请检查您的网络连接！'
      message && toastErr(message)
      return Promise.reject(resp)
    }),
    cancelHandler: () => {
      cancelHandler && cancelHandler()
    }
  }
}

export default Api
