<template>
  <div class="notes" @scroll="handleScroll($event)">
    <div class="goods-content" ref="listContainer">
      <div class="topic-wrapper">
        <div class="topic-list" v-loading="topicListLoading">
          <div class="topic-item" v-for="item in topicList" :key="item.id" @click="toTopicDetail(item)">
            <div class="topic-content">
              <div class="topic-img">
                <img :src="item.thumbnail || topicNoImg" alt="topic">
              </div>
              <div class="topic-info">
                <div class="topic-title">{{item.title}}</div>
                <div class="topic-hot">{{item.showCount}}看过</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" v-loading="noteListLoading">
        <template v-for="item in noteList">
          <div class="note-item" v-if="item.id" :key="item.id" @click="noteDetail(item)">
            <div class="video-img" v-if="item.videoTmpUrl">
              <img :src="videoImg || empty">
            </div>
            <div class="img">
              <img :src="item.thumbnails || empty">
            </div>
            <div class="center">
              {{item.title}}
            </div>
            <div class="user">
              <div class="head-img">
                <img :src="item.author.headImage">
              </div>
              <div>
                {{item.author.alias}}
              </div>
            </div>
          </div>
          <div v-else class="note-item"></div>
        </template>
      </div>
    </div>
    <el-dialog
        :visible.sync="detailVisible"
        :modal-append-to-body='false'
        :close-on-click-modal="false"
        custom-class="note-detail-modal"
        :show-close="false">
      <img class="close-btn" :src="guanbi" @click="closeDetailModal">
      <div class="container">
        <div class="left">
          <div class="center-video" v-if="content.video && videoInfo && videoInfo.url">
            <vod-video ref="vodVideo" :is-full="false" :cover="videoInfo.cover || ''" :url="videoInfo.url || ''"></vod-video>
          </div>
          <div class="center-img" v-else>
            <img :src="curImg">
            <div class="monitor-list">
              <div id="list-box" class="list-box">
                <div id="list" class="list">
                  <div v-for="(item, index) in allImgs" @click="changePic(index + 1, item)" :key="item" class="list-item">
                    <img :src="item" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="user">
            <div class="head-img">
              <img :src="author.headImage">
            </div>
            <div>
              {{author.alias}}
            </div>
          </div>
          <div class="note-info">
            <div class="title">{{allDetailInfo.title}}</div>
            <div class="content">
              {{allDetailInfo.summary}}
            </div>
            <div class="topic">
              {{showTopicContent}}
            </div>
          </div>
          <div class="comment">
            <div class="title">
              精选评论
            </div>
            <div v-for="item in commentList" class="comment-item">
              <div class="user">
                <div class="head-img">
                  <img :src="item.user.headImage">
                </div>
                <div>
                  {{item.user.alias}}
                </div>
              </div>
              <div class="cont">
                {{item.showContent[0].content}}
              </div>
            </div>
          </div>
          <div class="bottom-down">
            <div class="down-img" :style="{ backgroundImage: `url('${ qrCode || '/img/pages/life/icon_topic.png'}')` }"></div>
            <div class="info">
              <div class="info-top">微信扫码下载欧拉鼠App</div>
              <div class="info-bottom">即刻抢购商品！</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <router-view></router-view>
  </div>
</template>

<script>
import {dateFormat} from '@/utils/date'
import empty from '/src/assets/empty.png'
import topicNoImg from '/src/assets/note/topic-no-img.png'
import VodVideo from '@/components/VodVideo.vue'
import guanbi from '@/assets/icon_guanbi.png'
import videoImg from '@/assets/icons/icon_shipin.png'
import qrCode from '/src/assets/goods/qr-code-pc.png'
export default {
  name: 'HomePage',
  components: {VodVideo},
  data () {
    return {
      topicListLoading: false,
      noteListLoading: false,
      detailVisible: false,
      empty: empty,
      topicNoImg: topicNoImg,
      guanbi: guanbi,
      videoImg: videoImg,
      qrCode: qrCode,
      goods: {},
      info: {},
      curImg: undefined,
      originNotesList: [],
      noteList: [],
      topicList: [],
      commentList: [],
      content: {},
      debounceGetWindowWidth: undefined,
      //滑动
      monitorList: [],
      curPic: 1,
      imgList: {
        alive: '',
        down: ''
      },
      allDetailInfo: {},
      author: {},
      showTopicContent: '',
      goodsDetailInfo: {},
      curSeconds: undefined,
      curDate: undefined,
      pagination: {
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0
      },
      hasMore: false
    }
  },
  computed: {
    videoInfo () {
      if (!this.content.video) return null
      const url = this.content.video.url || this.allDetailInfo.videoTmpUrl
      if (url && this.content.video.thumbnail) {
        return {
          cover: this.content.video.thumbnail,
          url
        }
      }
      return null
    },
    allImgs () {
      let arr = this.imageObjectList.map(e => e.content)
      this.curImg = arr[0]
      return arr
    },
    detail () {
      return this.content.detail || []
    },
    imageObjectList () {
      return this.detail.filter(e => e.type === 1)
    },
  },
  async mounted() {
    let dateInMillisecs = Date.now()
    this.curSeconds = Math.round(dateInMillisecs / 1000)
    let d = new Date()
    this.curDate = dateFormat(d)
    await this.loadNotesList()
    this.loadTopicList ()

    this.debounceGetWindowWidth = _.debounce(this.getWindowWidth, 100)
    this.debounceLoadGoodsList = _.debounce(this.loadNotesList, 50)
    window.addEventListener('resize', this.debounceGetWindowWidth)
    this.getWindowWidth()
  },
  destroyed() {
    window.removeEventListener('resize', this.debounceGetWindowWidth)
  },
  methods: {
    handleScroll (event) {
      //触底判断
      if(event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100){
        if (!this.loading && this.hasMore) {
          this.debounceLoadGoodsList(this.pagination.pageIndex + 1)
        }
      }
    },
    numberFormat (value) {
      if (!value) return '0'

      const number = Number(value)

      if (isNaN(number)) return '0'

      if (number < 10000) {
        return number.toString()
      } else if (number < 100000000) {
        const wan = (number / 10000).toFixed(1)
        return wan.replace('.0', '') + '万'
      } else {
        const yi = (number / 100000000).toFixed(1)
        return yi.replace('.0', '') + '亿'
      }
    },
    getWindowWidth () {
      const width = this.$refs.listContainer.offsetWidth
      const maxItems = Math.floor(width / 354)
      let needNum = Math.floor(this.originNotesList.length % maxItems)
      if (needNum) {
        this.noteList = _.cloneDeep(this.originNotesList)
        let num = maxItems - needNum
        for (let i = 0; i < +num; i++) {
          this.noteList.push({})
        }
      } else {
        this.noteList = _.cloneDeep(this.originNotesList)
      }
    },
    toTopicDetail (item) {
      this.$router.push({
        path: '/topics',
        query: {
          id: item.id
        }
      })
    },
    loadTopicList () {
      this.topicListLoading = true
      this.Service.call('api/topic/recommend', 'GET', ).then(rst => {
        if (rst && rst.length) {
          rst.forEach(e => {
            e.showCount = this.numberFormat(e.readCount)
          })
        }
        this.topicList = _.cloneDeep(rst)
      }).finally(() => {
        this.topicListLoading = false
      })
    },
    loadCommentList (item) {
      this.Service.call('api/community/post/comment/list', 'GET', {
        postId: item.id,
        pageIndex: 1,
        queryTime: this.curDate
      }).then(rst => {
        if (rst.items.length) {
          rst.items.forEach(e => {
            e.showContent = JSON.parse(e.content)
          })
        }
        this.commentList = rst.items.length > 2 ? rst.items.slice(0,2) : _.cloneDeep(rst.items)
      }).finally(() => {
      })
    },
    async loadNotesList () {
      let lastId = !this.originNotesList.length ? undefined :this.originNotesList[this.originNotesList.length - 1].id
      this.noteListLoading = true
      await this.Service.call('api/community/post/recommend', 'GET', {
        time: this.curSeconds,
        lastId: lastId || undefined,
        // contentType: 2, // 1是图片 2是视频
        recommendSize: this.pagination.pageSize
      }).then(rst => {
        this.hasMore = rst.hasMore
        this.noteList = [...this.originNotesList, ...rst.items]
        this.originNotesList = [...this.originNotesList, ...rst.items]
        this.getWindowWidth()
      }).finally(() => {
        this.noteListLoading = false
      })
    },
    noteDetail (item) {
      let curPath = window.location.href
      const isDev = curPath.includes('localhost') || curPath.includes('mdev')
      const width = this.$refs.listContainer.offsetWidth
      if (width < 1100) {
        if (isDev) {
          const path = `https://mdev.olssglobal.com/post?id=${item.id}`
          window.open(path, '_blank')
        } else {
          const path = `https://m.olssglobal.com/post?id=${item.id}`
          window.open(path, '_blank')
        }
      } else {
        this.loadCommentList(item)
        this.Service.call('api/community/post/detail', 'GET', {
          id: item.id
        }).then(rst => {
          this.allDetailInfo = rst
          // this.goodsDetailInfo = rst.goods
          this.author = rst.author
          let str = ''
          rst.topics.forEach(item => {
            str += `#${item.title}`
          })
          this.showTopicContent = str
          this.content = JSON.parse(rst.content) || {}
        })
        this.detailVisible = true
      }
    },
    closeDetailModal () {
      if (this.content.video && this.content.video.thumbnail) {
        this.$refs.vodVideo && this.$refs.vodVideo.player.pause()
      }
      this.detailVisible = false
    },
    changePic (index, item) {
      if (this.curPic === index) return
      if (this.curPic < index) {
        this.scrollRight(index)
        this.curPic = index
        this.curImg = item
      } else {
        this.scrollLeft(index)
        this.curPic = index
        this.curImg = item
      }
    },
    // 左滑动逻辑
    scrollLeft(index) {
      const allLength = this.allImgs.length * 60
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength - 100 < boxLength) {
        // 到最开始的时候
        listEl.style.left = '0px'
      } else {
        listEl.style.left = '-' + (leftMove - 120) + 'px'
      }
    },
    // 右滑动逻辑
    scrollRight(index) {
      const allLength = this.allImgs.length * 60
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength > allLength - 484) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 120) + 'px'
      }
    }
  }
}
</script>

<style lang="stylus">
.el-loading-mask
  position fixed
.notes
  position fixed
  top 70px
  left 0
  right 0
  bottom 0
  overflow auto
  .goods-content
    background white
    padding 0 20px 50px 20px  // 设置两边固定 padding 为 20px
    .topic-wrapper
      margin-bottom 12px
      .topic-list
        display flex
        background #F6F6F6
        overflow-x auto
        -webkit-overflow-scrolling touch
        scroll-behavior smooth

        // 隐藏滚动条但保持功能
        &::-webkit-scrollbar
          display none
        -ms-overflow-style none  // IE and Edge
        scrollbar-width none     // Firefox

        .topic-item
          flex 0 0 auto         // 防止项目被压缩
          margin-right 20px     // 项目间距
          &:last-child
            margin-right 0

          .topic-content
            display flex
            align-items center
            padding 8px
            min-width 140px
            cursor pointer

            .topic-img
              width 40px
              height 40px
              margin-right 8px
              flex-shrink 0      // 防止图片被压缩

              img
                width 100%
                height 100%
                object-fit cover
                border-radius 4px

            .topic-info
              flex 1
              max-width 100px
              min-width 0        // 防止文本溢出

              .topic-title
                font-size 14px
                color #333
                margin-bottom 4px
                white-space nowrap
                overflow hidden
                text-overflow ellipsis

              .topic-hot
                font-size 12px
                color #999
    .content
      display grid
      grid-template-columns repeat(auto-fill, minmax(260px, 1fr))  // 使用 1fr 让列宽自适应
      gap 12px  // 增加间距使布局更加通透
      padding 0 20px 0px 0px  // 给 content 添加左右内边距
      box-sizing border-box  // 确保 padding 不会影响整体宽度
      .note-item
        width 100%
        padding 10px  // 给卡片增加内边距
        background #fff
        border-radius 8px
        position relative
        transition transform 0.2s ease
        &:hover
          cursor pointer
        .video-img
          position absolute
          top 20px
          right 18px
          img
            width 30px
            height 30px
        .img
          width 100%
          height auto
          aspect-ratio 1 / 1
          border-radius 4px
          overflow hidden
          img
            width 100%
            height 100%
            object-fit cover
        .center
          margin 6px 0
          min-height 36px
          font-size 14px
          font-weight bold
          word-wrap break-word
          flex-wrap wrap
          letter-spacing 2px
          overflow hidden
          text-overflow ellipsis
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 2
        .user
          display flex
          margin-top 10px
          font-size 14px
          .head-img
            width 24px
            height 24px
            margin-right 12px
            >img
              width 24px
              height 24px
              border-radius 50%
        .bottom-p
          padding 0 6px
          margin-top 6px
          display flex
          justify-content space-between
          .price
            font-weight bold
            font-size 16px
            >span
              font-size 12px
          .view-cmt
            font-size 12px
            color #999999
.note-detail-modal
  width 1000px !important
  height 653px
  position relative
  .close-btn
    z-index 9999
    cursor pointer
    position absolute
    right -50px
  .el-dialog__header
    display none
  .el-dialog__body
    padding 0
    height 100%
    .container
      display flex
      height 100%
      >div
        flex 1
      >.left
        width 500px
        display flex
        height 100%
        flex-direction column
        border-right 1px solid #EEEEEE
        .center-video
          width 100%
          height 100%
        .center-img
          position relative
          width 500px
          height 100%
          >img
            width 100%
            height 100%
            object-fit contain
          .monitor-list
            position absolute
            bottom 10px
            display flex
            justify-content space-between
            padding 0 16px
            .list-box
              width 484px
              overflow hidden
              .list
                width 484px
                display flex
                transform all 2s
                position relative
                left 0
                transition left 1s
                .list-item
                  width 48px
                  height 48px
                  min-width @width
                  text-align center
                  cursor pointer
                  margin-right 12px
                  img
                    width 100%
                    height 100%
                    object-fit cover
      >.right
        width 499px
        display flex
        flex-direction column
        position relative
        >.user
          padding 24px 24px 16px 24px
          display flex
          font-size 14px
          .head-img
            width 24px
            height 24px
            margin-right 12px
            >img
              width 24px
              height 24px
              border-radius 50%
        .note-info
          padding 0 24px 24px 24px
          background white
          .title
            font-size 18px
            font-weight 500
            margin-bottom 8px
            overflow hidden
            color #333333
            text-overflow ellipsis
            display -webkit-box;
            -webkit-box-orient vertical
            -webkit-line-clamp 2
          .content
            font-size 16px
            max-height 148px
            color #666666
            overflow hidden
            text-overflow ellipsis
            margin-bottom 8px
          .topic
            font-size 16px
            overflow hidden
            color #6E6BB9
            text-overflow ellipsis
            display -webkit-box;
            -webkit-box-orient vertical
            -webkit-line-clamp 2
        .comment
          margin 0 24px
          border-top 1px solid #EEEEEE
          padding-top 16px
          .title
            font-size 16px
            margin-bottom 15px
            font-weight 500
            color #333
          .comment-item
            margin-bottom 16px
            .user
              margin 6px 0
              display flex
              align-items center
              .head-img
                width 24px
                height 24px
                margin-right 12px
                >img
                  width 24px
                  height 24px
                  border-radius 50%
            .cont
              font-size 16px
              overflow hidden
              color #333333
              text-overflow ellipsis
              display -webkit-box;
              -webkit-box-orient vertical
              -webkit-line-clamp 2
        .bottom-down
          position absolute
          bottom 0
          display flex
          margin 0 24px
          padding 16px 0
          border-top 1px solid #EEEEEE;
          .down-img
            width 72px
            height 72px
            min-width @width
            min-width @height
            margin-right 10px
            background-repeat no-repeat
            background-size cover
            color #666666
          .info
            display flex
            width 378px
            height 70px
            justify-content space-around
            flex-direction column
            color #666666
</style>
