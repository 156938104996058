let toDate = function (date) {
  if (!date) {
    return new Date()
  }
  if (typeof date === 'string') {
    date = new Date(date.split('.')[0].replace('T', ' ').replace(/-/g, '/'))
  } else if (typeof date === 'number') {
    date = new Date(date)
  }
  return date
}

/*
* 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符
* 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
* 如："yyyy-MM-dd hh:mm:ss.S"
* fmt 默认为 'yyyy-MM-dd HH:mm'
* */
const dateFormat = function (date, fmt) {
  if (!date) {
    return '--'
  }
  date = toDate(date)
  fmt = fmt || 'YYYY-MM-DD HH:mm'
  let o = {
    'M+': date.getMonth() + 1, // 月份
    '[dD]+': date.getDate(), // 日
    '[hH]+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds(), // 毫秒
    'w+': ['日', '一', '二', '三', '四', '五', '六'][date.getDay()] // 星期
  }
  if (/([yY]+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  }
  return fmt
}

let oneMin = 60
let oneHour = oneMin * 60
let oneDay = 24 * oneHour
let oneMonth = 30 * oneDay
let oneYear = 365 * oneDay // 忽略：各月份和年份的天数不同

let dateDistanceWord = timeStr => {
  const now = new Date()
  let date = toDate(timeStr)
  let sec = (now.getTime() - date.getTime()) / 1000
  let year = ~~(sec / oneYear)
  if (year >= 1) return dateFormat(date, 'yyyy-MM-dd')
  let mon = ~~(sec / oneMonth)
  if (mon >= 1) return dateFormat(date, 'MM-dd')
  let day = ~~(sec / oneDay)
  if (day >= 7) {
    return dateFormat(date, 'MM-dd')
  } else if (day >= 1) {
    return day + '天前'
  }
  // 判断是否跨天了
  const isYesterday = dateFormat(date, 'yyyy-MM-dd') !== dateFormat(now, 'yyyy-MM-dd')
  let hour = ~~(sec / oneHour)
  if (hour >= 0) {
    return `${isYesterday ? '昨天' : ''}${(date.getHours() + '').padStart(2, '0')}:${(date.getMinutes() + '').padStart(2, '0')}`
  }
  let min = ~~(sec / oneMin)
  if (min >= 0) {
    return `${min}分钟前`
  }
  return '刚刚'
}


export {
  toDate,
  dateFormat,
  dateDistanceWord
}
