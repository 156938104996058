<template>
  <div class="goods" @scroll="handleScroll($event)">
    <!-- 头部banner -->
    <section class="banner">
      <!-- PC端banner -->
      <div class="pc-banner" v-if="isPC">
        <img :src="banner" alt="banner">
      </div>

      <div class="pc-banner" v-if="isBigMoblie">
        <img :src="bigPhoneBanner" alt="banner">
      </div>

      <div class="mobile-banner" v-if="isMobile">
        <img :src="bannerPhone" alt="banner">
      </div>

      <!-- 下载区域 -->
      <div class="download-area">
        <div class="qr-code">
          <img :src="isMobile ? phoneQrCode : pcQrCode" alt="QR Code">
        </div>
        <div class="download-buttons">
          <div class="app-store">
            <img :src="isMobile ? phoneAppStoreBtn : pcAppStoreBtn" alt="App Store">
          </div>
        </div>
      </div>
    </section>
    <template>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabList" :key="index" :label="item.mallCatName" :name="item.catId + ''">
        </el-tab-pane>
      </el-tabs>
    </template>
    <div class="you-like" ref="listContainer" v-loading="loading">
      <div class="content" @scroll="handleScroll">
        <template v-for="item in goodsList">
          <div class="goods-item" v-if="item.goodsId" :key="item.goodsId" @click="Detail(item)">
              <div class="img">
                <img :src="item.goodsImgUrl || empty">
              </div>
              <div class="center">
                <div class="cs-tag">
                  <img :src="yijian">
                  <span class="cs">{{item.showQuality}}</span>
                </div>
                <span class="name">
                {{item.goodsName}}
              </span>
              </div>
              <div class="bottom-p">
                <div class="price">
                  <span>￥</span>{{item.salePrice / 100}}
                </div>
                <div class="view-cmt">
                  {{item.viewCnt}}人看过
                </div>
              </div>
          </div>
          <div v-else class="goods-item"></div>
        </template>
      </div>
    </div>
    <el-dialog
        :visible.sync="detailVisible"
        :modal-append-to-body='false'
        custom-class="detail-modal"
        :show-close="false">
      <img class="close-btn" :src="guanbi" @click="closeDetailModal">
      <div class="container">
        <div class="left">
          <div class="top-img">
            <img :src="gfrz">
          </div>
          <div class="center-img">
            <img :src="curImg">
            <div class="monitor-list">
              <div id="list-box" class="list-box">
                <div id="list" class="list">
                  <div v-for="(item, index) in allImgs" @click="changePic(index + 1, item)" :key="item.id" class="list-item">
                    <img :src="item.imgUrl" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="identification-results">
              <div class="cs" v-if="goodsDetailInfo.quality === 1">
                <img :src="eight">
                <div class="des">严重使用痕迹或整体老旧，外观有严重使用痕迹、磨损、损伤、不可除污渍、氧化、老化等，可正常使用</div>
              </div>
              <div class="cs" v-if="goodsDetailInfo.quality === 2">
                <img :src="eightFive">
                <div class="des">明显使用痕迹或整体泛旧，外观有明显使用痕迹、磨损、损伤、不可除污渍、氧化、老化等，可正常使用</div>
              </div>
              <div class="cs" v-if="goodsDetailInfo.quality === 3">
                <img :src="nine">
                <div class="des">正常使用痕迹，外观有正常使用划痕、磨损、小面积污渍、氧化等，整体状态良好</div>
              </div>
              <div class="cs" v-if="goodsDetailInfo.quality === 4">
                <img :src="nineFive">
                <div class="des">轻微使用痕迹，外观有轻微或使用划痕磨损等，整体状态优</div>
              </div>
              <div class="cs" v-if="goodsDetailInfo.quality === 5">
                <img :src="nineNine">
                <div class="des">细微使用痕迹，外观极细微擦痕，外观品相佳</div>
              </div>

              <div class="cs" v-if="goodsDetailInfo.quality === 6">
                <img style="width: 110px" :src="newImg">
                <div class="des">陈列保存痕迹，外观与新品无差异</div>
              </div>
            </div>
          </div>
          <div class="goods-info">
            <div class="row">
              <div class="left">
                <div class="price">￥<span class="new-price">{{goodsDetailInfo.salePrice / 100}}</span></div>
                <div class="tag-row">
                  <div v-if="!goodsDetailInfo.postPrice">包邮</div>
                  <div v-if="goodsDetailInfo.postPrice">运费￥{{goodsDetailInfo.postPrice / 100}}</div>
                  <div v-if="goodsDetailInfo.pointsPlanPrice">欧拉币可抵￥{{goodsDetailInfo.pointsPlanPrice / 100}}</div>
                </div>
              </div>
              <div class="right">
                <div class="old-price" v-if="+goodsDetailInfo.newPrice">￥{{(goodsDetailInfo.newPrice || 0) / 100}}全新价</div>
                <div class="stock">{{ allDetailInfo.viewCnt }}人看过</div>
              </div>
            </div>
            <div class="name-row">
              {{goodsDetailInfo.goodsName}}
            </div>
          </div>
        </div>
        <div class="right">
          <div class="shop-info">
            <div class="title">店铺信息</div>
            <div class="shop">
              <div class="logo" :style="{ backgroundImage: `url('${shopInfo.logo || '/img/pages/life/icon_topic.png'}')` }"></div>
              <div class="name">
                <div class="shop-name">{{shopInfo.name}}</div>
                <div class="shop-address">{{shopInfo.province}}-{{shopInfo.city}}-{{shopInfo.area}}</div>
                <div class="sale-count">
                  {{shopInfo.sellCnt}}件在售
                </div>
              </div>
            </div>
          </div>
          <div class="goods-des">
            <div class="title">商品描述</div>
            <div class="content">
              {{goodsDetailInfo.brief}}
            </div>
          </div>
          <div class="bottom-down">
            <div class="down-img" :style="{ backgroundImage: `url('${ qrCode || '/img/pages/life/icon_topic.png'}')` }"></div>
            <div class="info">
              <div class="info-top">微信扫码下载欧拉鼠App</div>
              <div class="info-bottom">即刻抢购商品！</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import empty from '/src/assets/empty.png'
import yijian from '/src/assets/goods/icon_yiyan.png'
import gfrz from '/src/assets/goods/gfrz.png'
import eight from '/src/assets/goods/img_8.png'
import eightFive from '/src/assets/goods/img_85.png'
import nine from '/src/assets/goods/img_9.png'
import nineFive from '/src/assets/goods/img_95.png'
import nineNine from '/src/assets/goods/img_99.png'
import newImg from '/src/assets/goods/img_quanxin.png'
import weitongguo from '/src/assets/goods/img_weitongguo.png'
import banner from '@/assets/goods/banner.png'
import bannerPhone from '@/assets/goods/banner-phone.png'
import bigPhoneBanner from '@/assets/goods/big-phone-banner.png'
import phoneQrCode from '@/assets/goods/qr-code-phone.png'
import pcQrCode from '@/assets/goods/qr-code-pc.png'
import pcAppStoreBtn from '@/assets/goods/down-tip-pc.png'
import phoneAppStoreBtn from '@/assets/goods/down-tip-phone.png'
import guanbi from '@/assets/icon_guanbi.png'
import qrCode from '@/assets/goods/qr-code-pc.png'
export default {
  name: 'HomePage',
  data () {
    return {
      loading: false,
      browserKey: '',
      eight: eight,
      eightFive: eightFive,
      nine: nine,
      newImg: newImg,
      nineFive: nineFive,
      weitongguo: weitongguo,
      nineNine: nineNine,
      qrCode: qrCode,
      empty: empty,
      yijian: yijian,
      gfrz: gfrz,
      banner: banner,
      bigPhoneBanner: bigPhoneBanner,
      bannerPhone: bannerPhone,
      guanbi: guanbi,
      phoneQrCode: phoneQrCode,
      pcQrCode: pcQrCode,
      pcAppStoreBtn: pcAppStoreBtn,
      phoneAppStoreBtn: phoneAppStoreBtn,
      isMobile: false,
      activeName: undefined,
      detailVisible: false,
      curImg: undefined,
      isPC: false,
      isBigMoblie: false,
      originGoodsList: [],
      tabList: [],
      goodsList: [],
      qualityEnums: [
        {
          name: '已验8成新',
          val: 1,
          count: 8,
          showCount: '严重使用痕迹或整体老旧，外观有严重使用痕迹、磨损、损伤、不可除污渍、氧化、老化等，可正常使用。',
        },
        {
          name: '已验85新',
          val: 2,
          count: 85,
          showCount: '明显使用痕迹或整体泛旧，外观有明显使用痕迹、磨损、损伤、不可除污渍、氧化、老化等，可正常使用。',
        },
        {
          name: '已验9成新',
          val: 3,
          count: 9,
          showCount: '正常使用痕迹，外观有正常使用划痕、磨损、小面积污渍、氧化等，整体状态良好。',
        },
        {
          name: '已验95成新',
          val: 4,
          count: 95,
          showCount: '轻微使用痕迹，外观有轻微或使用划痕磨损等，整体状态优。',
        },
        {
          name: '已验99新',
          val: 5,
          count: 99,
          showCount: '细微使用痕迹，外观极细微擦痕，外观品相佳。',
        },
        {
          name: '已验全新',
          val: 6,
          count: '全',
          showCount: '陈列保存痕迹，外观与新品无差异。',
        }
      ],
      debounceGetWindowWidth: undefined,
      //滑动
      curPic: 1,
      allDetailInfo: {},
      goodsDetailInfo: {},
      allImgs: [],
      shopInfo: {},
      pagination: {
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0
      },
      hasMore: false
    }
  },
  computed: {
  },
  async mounted() {
    this.browserKey = this.generateBrowserKey()
    await this.loadCategoryList ()
    if (Number(this.activeName)) {
      await this.loadGoodsList()
    } else {
      await this.loadRecommendGoodsList()
    }
    this.debounceGetWindowWidth = _.debounce(this.getWindowWidth, 20)
    this.debounceLoadGoodsList = _.debounce(this.loadGoodsList, 20)
    this.debounceLoadRecommendGoodsList = _.debounce(this.loadRecommendGoodsList, 20)
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
    window.addEventListener('resize', this.debounceGetWindowWidth)
    this.getWindowWidth()
  },
  destroyed() {
    window.removeEventListener('resize', this.debounceGetWindowWidth)
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  methods: {
    handleClick (item) {
      this.activeName = item.name
      if (Number(this.activeName)) {
        this.loadGoodsList()
      } else {
        this.goodsList = []
        this.originGoodsList = []
        this.loadRecommendGoodsList()
      }
    },
    handleScroll (event) {
      //触底判断
      if(event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100){
        if (Number(this.activeName)) {
          if (!this.loading && this.hasMore) {
            this.debounceLoadGoodsList(this.pagination.pageIndex + 1)
          }
        } else  {
          if (!this.loading) {
            this.debounceLoadRecommendGoodsList()
          }
        }
      }
    },
    Detail (item) {
      let curPath = window.location.href
      const isDev = curPath.includes('localhost') || curPath.includes('mdev')
      const width = this.$refs.listContainer.offsetWidth
      if (width < 1100) {
        if (isDev) {
          const path = `https://mdev.olssglobal.com/goods-detail?id=${item.goodsId}`
          window.open(path, '_blank')
        } else {
          const path = `https://m.olssglobal.com/goods-detail?id=${item.goodsId}`
          window.open(path, '_blank')
        }
      } else {
        this.Service.call('api/mall/uemall/GoodsDetail', 'POST', {
          goodsId: item.goodsId
        }).then(rst => {
          this.allDetailInfo = rst || {}
          this.goodsDetailInfo = rst.goods || []
          this.shopInfo = rst.shop || {}
          this.allImgs = [...this.allDetailInfo.baseImgs, ...this.allDetailInfo.detailImgs]
          this.curImg = this.allDetailInfo.baseImgs[0].imgUrl || ''
        })
        this.detailVisible = true
      }
    },
    closeDetailModal () {
      this.detailVisible = false
    },
    async loadCategoryList () {
      await this.Service.call('api/mall/uecategory/list', 'POST', {
        pageIndex: 1
      }).then(rst => {
        rst.push({
          mallCatName: '推荐',
          catId: 0,
          mallDisplayOrder: 9999999
        })
        this.tabList = rst.sort(this.sortBy('mallDisplayOrder', -1))
        this.activeName = rst.length ? rst[0].catId + '' : ''
      })
    },
    sortBy (attr, rev) {
      if (rev === undefined) {
        rev = 1
      } else {
        rev ? 1 : -1
      }
      return function (a,b){
        a = a[attr]
        b = b[attr]
        if (a<b) { return rev*-1}
        if (a>b) { return rev* 1 }
        return 0;
      }
    },
    async loadRecommendGoodsList () {
      this.loading = true
      await this.Service.call('api/mall/uemall/listUEGoodsRecommend', 'POST', {
        recommendSize: this.pagination.pageSize,
        clientKey: this.browserKey
      }).then(rst => {
        // this.pagination = rst.pagination
        // this.hasMore = pageIndex * this.pagination.pageSize < rst.pagination.totalCount
        if (rst.length) {
          rst.forEach(item => {
            let qualityInfo = this.qualityEnums.find(i => i.val === item.quality)
            item.showQuality = item.quality === 6 ? '全新' : qualityInfo.count > 80 ? `${qualityInfo.count}新` : `${qualityInfo.count}成新`
          })
        }
        this.goodsList = [...this.originGoodsList, ...rst]
        this.originGoodsList = [...this.originGoodsList, ...rst]
        // if (pageIndex === 1) {
        //   this.originGoodsList = rst.items
        //   this.goodsList = rst.items
        // } else {
        //   this.goodsList = [...this.originGoodsList, ...rst.items]
        //   this.originGoodsList = [...this.originGoodsList, ...rst.items]
        // }
        this.getWindowWidth()
      }).finally(() =>{
        this.loading = false
      })
    },
    async loadGoodsList (pageIndex) {
      pageIndex = +pageIndex || 1
      if (pageIndex > 1 && !this.hasMore) return
      this.loading = true
      await this.Service.call('api/mall/uemall/listGoodsByCategory', 'POST', {
        pageIndex: pageIndex || 1,
        pageSize: this.pagination.pageSize,
        catId: Number(this.activeName),
        sortType: 1
      }).then(rst => {
        this.pagination = rst.pagination
        this.hasMore = pageIndex * this.pagination.pageSize < rst.pagination.totalCount
        if (rst.items.length) {
          rst.items.forEach(item => {
            let qualityInfo = this.qualityEnums.find(i => i.val === item.quality)
            item.showQuality = item.quality === 6 ? '全新' : qualityInfo.count > 80  ? `${qualityInfo.count}新` : `${qualityInfo.count}成新`
          })
        }
        if (pageIndex === 1) {
          this.originGoodsList = rst.items
          this.goodsList = rst.items
        } else {
          this.goodsList = [...this.originGoodsList, ...rst.items]
          this.originGoodsList = [...this.originGoodsList, ...rst.items]
        }
        this.getWindowWidth()
      }).finally(() =>{
        this.loading = false
      })
    },
    generateBrowserKey() {
      // 获取浏览器信息
      const userAgent = navigator.userAgent;
      const browserInfo = {
        chrome: /chrome/i.test(userAgent),
        firefox: /firefox/i.test(userAgent),
        safari: /safari/i.test(userAgent),
        edge: /edge/i.test(userAgent),
        ie: /msie|trident/i.test(userAgent),
        opera: /opera/i.test(userAgent)
      };

      // 获取当前浏览器名称
      let browserName = Object.keys(browserInfo).find(key => browserInfo[key]) || 'unknown';

      // 生成随机字符串的字符集
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      // 计算剩余长度（50减去浏览器名称长度和分隔符）
      const remainingLength = 50 - browserName.length - 1;

      // 生成随机字符串
      let randomString = '';
      for (let i = 0; i < remainingLength; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        randomString += chars[randomIndex];
      }

      // 组合浏览器名称和随机字符串
      return `${browserName}_${randomString}`;
    },
    getWindowWidth () {
      const width = this.$refs.listContainer.offsetWidth
      this.isMobile = width < 430
      this.isPC = width > 767
      this.isBigMoblie = width >= 430 && width <= 767
    },
    changePic (index, item) {
      if (this.curPic === index) return
      if (this.curPic < index) {
        this.scrollRight(index)
        this.curPic = index
        this.curImg = item.imgUrl
      } else {
        this.scrollLeft(index)
        this.curPic = index
        this.curImg = item.imgUrl
      }
    },
    // 左滑动逻辑
    scrollLeft(index) {
      const allLength = this.allImgs.length * 60
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength - 100 < boxLength) {
        // 到最开始的时候
        listEl.style.left = '0px'
      } else {
        listEl.style.left = '-' + (leftMove - 120) + 'px'
      }
    },
    // 右滑动逻辑
    scrollRight(index) {
      const allLength = this.allImgs.length * 60
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength > allLength - 484) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 120) + 'px'
      }
    }
  }
}
</script>

<style lang="stylus">
.goods
  position fixed
  top 70px
  left 0
  right 0
  bottom 0
  overflow auto
  .el-tabs
    margin-top 12px
    padding 0 20px
    .el-tabs__item
      color #333333
      &:hover
        color #333333
    .is-active
      font-weight 500
      color black
    .el-tabs__active-bar
      background-color black
  .banner
    position relative
    width 100%
    background #151519
    overflow hidden

    .pc-banner
      width 100%
      height 280px
      display none    // 默认都隐藏

      img
        width 100%
        height 100%
    .mobile-banner
      width 100%
      height auto

      img
        width 100%
        height auto
    .download-area
      position absolute
      //left 33%
      //top 72%
      //transform translate(-50%, -50%)
      display flex
      align-items center
      gap 20px

      .qr-code
        img
          width 100%
          height 100%
          object-fit contain

      .download-buttons
        .app-store
          img
            width 100%
            height 100%
            object-fit contain
  .you-like
    min-height 800px
    background white
    padding 0 8px 50px 8px
    .content
      display grid  // 使用grid布局
      grid-template-columns repeat(auto-fit, minmax(280px, 1fr))  // 响应式列数
      gap: 16px
      padding: 16px

      .goods-item
        width 100%  // 宽度改为100%
        background #fff
        border-radius 8px
        overflow hidden
        transition all 0.3s ease
        &:hover
          cursor pointer

        .img
          width 100%
          padding-top 100%  // 保持1:1比例
          position relative
          overflow hidden

          img
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            object-fit cover

        .center
          margin-top 6px
          min-height 48px
          max-height 48px
          overflow hidden
          text-overflow ellipsis
          display -webkit-box
          -webkit-box-orient vertical
          -webkit-line-clamp 2
          .cs-tag
            padding 1px 2px
            display inline-block
            color #666666
            position relative
            border 1px solid #CCCCCC
            height 22px
            line-height 22px
            .cs
              height 22px
              position relative
              padding-right 5px
              right: -4px;
              bottom 1px
              display inline-block
              line-height 22px
              font-size 10px
            img
              margin-right 4px
              position relative
              top 0px
              width 20px
              height 10px
            &:before
              position absolute
              content ''
              background-color #CCCCCC
              width 1px
              left 26px
              top 6px
              height 13px
            .name
              font-size 14px
              max-height 38px
              font-weight bold
              overflow hidden;
              text-overflow ellipsis;
              display -webkit-box;
              -webkit-box-orient vertical;
              -webkit-line-clamp 2
        .bottom-p
          padding 8px
          display flex
          justify-content space-between
          align-items center

          .price
            font-size 16px
            font-weight bold
            color #000000

          .view-cmt
            font-size 12px
            color #999

.detail-modal
  width 1000px !important
  height 710px
  .close-btn
    z-index 9999
    cursor pointer
    position absolute
    right -50px
  .el-dialog__header
    display none
  .el-dialog__body
    padding 0
    .container
      display flex
      >div
        flex 1
      >.left
        width 500px
        display flex
        flex-direction column
        border-right 1px solid #EEEEEE
        .top-img
          width 500px
          height 84px
          img
            width 100%
            height 100%
        .center-img
          position relative
          width 500px
          height 500px
          >img
            width 100%
            height 100%
            object-fit contain
          .monitor-list
            position absolute
            bottom 10px
            display flex
            justify-content space-between
            padding 0 16px
            .list-box
              width 484px
              overflow hidden
              .list
                width 484px
                display flex
                transform all 2s
                position relative
                left 0
                transition left 1s
                .list-item
                  width 48px
                  height 48px
                  min-width @width
                  text-align center
                  cursor pointer
                  margin-right 12px
                  img
                    width 100%
                    height 100%
                    object-fit cover
          .identification-results
            position absolute
            width 468px
            top 0
            color white
            height 24px
            background linear-gradient( 180deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
            display flex
            align-items center
            padding 16px
            .cs
              display flex
              align-items center
              >img
                width 64px
                margin-right 12px
                height 56px
        .goods-info
          background white
          padding 8px 15px 16px 15px
          margin-bottom 15px
          .row
            display flex
            align-items center
            justify-content space-between
            >div
              display flex
              align-items center
              .price
                font-size 12px
                .new-price
                  font-weight bold
                  font-size 20px
                  color black
              .old-price
                color #666666
                font-weight normal
                font-size 14px
                margin-left 6px
              .tag-row
                margin-left 16px
                display flex
                height 37px
                align-items center
                >div
                  margin-right 6px
                  background black
                  color white
                  font-size 10px
                  padding 4px 6px
              .stock
                color #666
                font-size 14px
                margin-left 16px
          .name-row
            margin-top 6px
            font-size 16px
            color #333333
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            letter-spacing 1px
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
      >.right
        width 499px
        display flex
        flex-direction column
        .shop-info
          padding 20px
          background white
          .title
            font-size 16px
            font-weight bold
            margin-bottom 10px
          .shop
            display flex
            align-items center
            font-size 16px
            justify-content space-between
            .logo
              width 72px
              height 72px
              min-width @width
              min-width @height
              margin-right 10px
              background-repeat no-repeat
              background-size cover
            .name
              display flex
              width 378px
              height 70px
              justify-content space-between
              flex-direction column
              .shop-name
                font-weight 500
                font-size 16px
                color #333333
              .shop-address
                overflow hidden
                white-space nowrap
                color #666666
                text-overflow ellipsis
              .sale-count
                color #666666
        .goods-des
          flex 1
          border-top 1px solid #EEEEEE;
          margin 0 24px
          padding-top 16px
          .title
            font-size 16px
            font-weight 500
            color #333333
            margin-bottom 6px
          .content
            font-size 16px
            max-height 380px
            color #666666
            overflow hidden
            text-overflow ellipsis
        .bottom-down
          display flex
          margin 0 24px
          padding 24px 0
          border-top 1px solid #EEEEEE;
          .down-img
            width 72px
            height 72px
            min-width @width
            min-width @height
            margin-right 10px
            background-repeat no-repeat
            background-size cover
            color #666666
          .info
            display flex
            width 378px
            height 70px
            justify-content space-around
            flex-direction column
            color #666666
@media screen and (min-width: 1600px)
  .banner
    .pc-banner
      display block !important   // PC端显示大图
    .download-area
      left 21%     // 移动端居中显示
      top 55%
      .qr-code
        width 100px
        height 100px
      .download-buttons
        .app-store
          width 192px
          height 100px
@media screen and (min-width: 1200px) and (max-width: 1599px)
  .banner
    .pc-banner
      display block !important   // PC端显示大图
    .download-area
      left 21%     // 移动端居中显示
      top 55%
      .qr-code
        width 80px
        height 80px
      .download-buttons
        .app-store
          width 160px
          height 80px

// 平板样式 (768px-1199px)
@media screen and (min-width: 767px) and (max-width: 1199px)
  .banner
    .pc-banner
      height 240px !important
      display block !important
    .download-area
      left 21%     // 移动端居中显示
      top 55%
      gap 6px !important
      .qr-code
        width 60px
        height 60px
      .download-buttons
        .app-store
          width 120px
          height 60px


@media screen and (min-width: 561px) and (max-width: 767px)
  .banner
    .pc-banner
      display block !important   // PC端显示大图
      height 200px !important
      display block !important
    .download-area
      left 4%
      top 55%
      gap 6px !important
      .qr-code
        width 60px
        height 60px
      .download-buttons
        .app-store
          width 120px
          height 60px
// 大手机样式 (431px-767px)
@media screen and (min-width: 430px) and (max-width: 560px)
  .banner
    .pc-banner
      height 150px !important
      display block !important
    .download-area
      gap 6px !important
      left 8%   // 移动端居中显示
      top 54%
      .qr-code
        width 36px
        height 36px
      .download-buttons
        .app-store
          width 100px
          height 36px

@media screen and (max-width: 430px)
  .banner
    .mobile-banner
      height 150px
      display block !important    // 移动端显示小图
      >img
        height 150px !important
        object-fit contain !important
    .download-area
      left 9% !important
      top 65% !important
      gap 6px !important
      .qr-code
        width 32px
        height 32px
      .download-buttons
        .app-store
          width 80px
          height 32px
// 响应式断点
@media screen and (min-width: 1440px)
  .goods
    .you-like .content
      grid-template-columns repeat(5, 1fr)  // 大屏5列
@media screen and (max-width: 1200px)
  .goods
    .you-like .content
      grid-template-columns repeat(4, 1fr)  // 中屏4列

@media screen and (max-width: 992px)
  .goods
    .you-like .content
      grid-template-columns repeat(3, 1fr)  // 平板3列

@media screen and (max-width: 768px)
  .goods
    .you-like .content
      grid-template-columns repeat(2, 1fr)  // 手机2列
      gap: 8px
      padding: 8px

@media screen and (max-width: 480px)
  .goods
    .you-like .content
      grid-template-columns 1fr  // 小屏1列


</style>
