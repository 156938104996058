import Api from './base-api'
import Config from '../../config'
import _ from 'lodash'

export default (path, method, data, paramsInPath, postParams, responseType, noToken, silence) => {
  // 如果传入postParams，则表示是包含Body的请求，不处理 data
  if (!_.isArray(data) && _.isObject(data) && !postParams) {
    const validData = {}
    if (data) {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          if (data[key] !== undefined) {
            validData[key] = data[key]
          }
        }
      }
    }
    data = validData
  }
  return new Promise((resolve, reject) => {
    method = method && method.toLowerCase() || 'get'
    let urlToExtend = ''
    if (paramsInPath) {
      _.forEach(paramsInPath, param => {
        urlToExtend += '/' + encodeURI(param)
      })
    }
    Api.http(
      _.startsWith(path, 'http') || _.startsWith(path, '/') ?
        `${path}${urlToExtend}` : Config.BASE_URL + `${path}${urlToExtend}`,
      method,
      data,
      postParams,
      false,
      responseType,
      noToken,
      silence
    ).promise.then(rst => {
      resolve(rst)
    }, resp => {
      reject(resp)
    })
  })
}
