<template>
  <div class="page-header" ref="headerContainer">
    <div class="header-logo" @click="toPath('/')">
      <img :src="imgLogo">
      <img class="slogan" :src="slogan">
    </div>
    <div class="center">
      <div v-for="item in tab" :class="{'active': item.value.includes(curPath)}" @click="toPath(item.path)">{{item.name}}</div>
    </div>
    <div class="right">
      <div class="down-pc" @click="downLoadApp">下载APP</div>
      <div class="down-phone">
        <img :src="downImg" @click="PhoneDownLoadApp">
      </div>
      <div class="menu" @click="drawer = true">
        <img :src="menuImg">
      </div>
    </div>
    <el-dialog
      :visible.sync="downLoadVisible"
      :modal-append-to-body='false'
      width="500"
      custom-class="down-load-modal"
      :show-close="false">
      <div class="dialog-content">
        <div class="close-btn" @click="downLoadVisible = false">×</div>
        <div class="header">
          <img src="@/assets/downLoadTop.png" alt="logo" class="logo">
          <h2 class="title">欧拉鼠 爱不分新旧</h2>
          <p class="subtitle">二手奢侈品电商平台</p>
        </div>

        <!-- 二维码区域 -->
        <div class="qrcode-wrapper">
          <img :src="qrCode" alt="二维码" class="qrcode">
          <p class="qrcode-tip">微信扫码下载/打开App</p>
        </div>
      </div>
    </el-dialog>
    <el-drawer
        :visible.sync="drawer"
        direction="ttb"
        :with-header="false">
      <div class="drawer-header">
        <div class="header-logo">
          <img :src="imgLogoWhite">
          <img class="slogan" :src="sloganWhite">
        </div>
        <div class="right">
          <div class="down-phone" @click="PhoneDownLoadApp">
            <img :src="downWhiteImg" @click="PhoneDownLoadApp">
          </div>
          <div class="menu" @click="drawer = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
      </div>
      <div class="drawer-content">
        <div v-for="item in tab" :class="{'active': item.value.includes(curPath)}" @click="toPath(item.path)">
          <div>
            {{item.name}}
          </div>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import imgLogo from '/src/assets/logo.png'
import slogan from '/src/assets/slogan.png'
import imgLogoWhite from '/src/assets/logo-white.png'
import sloganWhite from '/src/assets/slogan-white.png'
import downImg from '/src/assets/down.png'
import downWhiteImg from '/src/assets/down-white.png'
import menuImg from '/src/assets/menu.png'
import qrCode from '/src/assets/goods/qr-code-pc.png'
import {isIOSPhone} from '@/utils/checkBrowser'
export default {
  name: 'Header',
  data () {
    return {
      drawer: false,
      downLoadVisible: false,
      imgLogo: imgLogo,
      slogan: slogan,
      sloganWhite: sloganWhite,
      imgLogoWhite: imgLogoWhite,
      downImg: downImg,
      qrCode: qrCode,
      downWhiteImg: downWhiteImg,
      menuImg: menuImg,
      tab: []
    }
  },
  computed: {
    curPath () {
      return this.$route.path
    }
  },
  mounted() {
    this.debounceGetWindowWidth = _.debounce(this.getWindowWidth, 20)
    window.addEventListener('resize', this.debounceGetWindowWidth)
    this.getWindowWidth()
  },
  destroyed() {
    window.removeEventListener('resize', this.debounceGetWindowWidth)
  },
  methods: {
    getWindowWidth () {
      const width = this.$refs.headerContainer.offsetWidth
      if (width < 900) {
        this.tab = [
          {
            name: '商城',
            path: '/goods',
            value: '/goods'
          },
          {
            name: '社区',
            path: '/notes',
            value: '/notes/topics'
          },
          {
            name: '关于我们',
            path: '/about',
            value: '/about'
          }
        ]
      } else {
        this.tab = [
          {
            name: '商城',
            path: '/goods',
            value: '/goods'
          },
          {
            name: '社区',
            path: '/notes',
            value: '/notes/topics'
          },
          {
            name: '关于我们',
            path: '/about',
            value: '/about'
          },
          {
            name: '创作中心',
            path: '/creator',
            value: '/creator'
          },
        ]
      }
    },
    downLoadApp () {
      this.downLoadVisible = true
    },
    isIOSPhone () {
      return /(iPhone|iPod|iPad);?/i.test(navigator.userAgent)
    },
    PhoneDownLoadApp () {
      if (this.isIOSPhone()) {
        window.location.href = this.Config.APP_URL.IOS;
      } else {
        // 下载APK
        // 获取最新的APK下载地址，然后下载
        this.Service.call("api/app/version/android", "GET")
          .then(rst => {
            let success = false;
            if (rst) {
              if (rst.download) {
                success = true;
                window.location.href = rst.download;
              }
            }
            if (!success) {
              window.location.href = this.Config.APP_URL.ANDROID;
            }
          })
          .catch(() => {
            window.location.href = this.Config.APP_URL.ANDROID;
          });
      }
    },
    toPath(path) {
      if (path === '/creator') {
        if (window.location.href.includes('dev') || window.location.href.includes('localhost')) {
          window.open('https://mpdev.olssglobal.com/login')
        } else {
          window.open('https://mp.olssglobal.com/login')
        }
      } else {
        this.$router.push(path)
        this.drawer = false
      }
    }
  }
}
</script>

<style lang="stylus">
.el-drawer
  width 100% !important
  height 100% !important
  .el-drawer__body
    width 100%
    background black
    .drawer-header
      min-width 350px
      border-bottom 1px solid #333
      height 70px
      align-items center
      padding 20px 28px 10px 28px
      display flex
      justify-content space-between
      .right
        .down-phone
          font-size 32px
          display block
          margin-right 20px
          cursor pointer
          color white
          >img
            width
        .menu
          cursor pointer
          font-size 32px
          display block
          color white
    .drawer-content
      min-width 350px
      >div
        display flex
        height 72px
        font-size 18px
        justify-content space-between
        margin 0 24px
        align-items center
        color white
        cursor pointer
        .el-icon-arrow-right
          font-size 22px
      .active
        color #FE5102
        font-weight 500
.page-header
  height 32px
  line-height 32px
  padding 12px 40px
  display flex
  justify-content space-between
  .header-logo
    min-width 260px
    cursor pointer
    >img
      height 32px
    .slogan
      margin-left 24px
  .center
    display flex
    >div
      margin-right 48px
      cursor pointer
    .active
      color #FE5102
      font-weight 500
      border-bottom 1px solid #FE5102
  .right
    display flex
    .menu
      display none
    .down-phone
      display none
    .down-pc
      cursor pointer
      width 104px
      color white
      text-align center
      line-height 36px
      height 36px
      background #000000
@media screen and (max-width: 900px)
  .page-header
    height 32px
    padding 12px 20px
    .center
      display none
    .right
      .down-pc
        display none
      .down-phone
        font-size 32px
        display block
        margin-right 20px
        cursor pointer
        >img
          width 32px
          height 32px
      .menu
        cursor pointer
        font-size 32px
        display block
        >img
          width 32px
          height 32px

@media screen and (max-width: 430px)
  .drawer-header
    .slogan
      display none

.down-load-modal
  width 560px !important
  .el-dialog__header
    display none
  .el-dialog__body
    padding 0
    .dialog-content
      position relative
      background #fff
      border-radius 8px
      padding 30px
      text-align center

      .close-btn
        position absolute
        right 20px
        top 20px
        font-size 24px
        cursor pointer
        color #999

      .header
        margin-bottom 30px

        .logo
          width 120px
          height 120px

        .title
          font-size 24px
          font-weight bold
          color #333
          margin-bottom 8px

        .subtitle
          font-size 16px
          color #666

      .qrcode-wrapper
        .qrcode
          width 120px
          height 120px
          margin-bottom 15px

        .qrcode-tip
          font-size 14px
          color #666

</style>
