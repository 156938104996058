/* eslint-disable dot-notation */
import {toDate, dateFormat, dateDistanceWord} from './date'
import {checkBrowser, isMobile, isSafari, isIOSPhone, isAndroid, isWeixin, isQQ} from './checkBrowser'
import currencyFormatter from './currency-formater'
import _ from 'lodash'

const cookieEnabled = function () {
  return window.navigator.cookieEnabled
}

const getCookie = function (name) {
  let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  let arr = document.cookie.match(reg)
  if (arr) {
    return decodeURI(arr[2])
  } else {
    return null
  }
}

const deleteCookie = function (name) {
  let exp = new Date()
  exp.setTime(exp.getTime() - 1)
  let cval = getCookie(name)
  if (cval) {
    document.cookie = name + '=' + cval + ';expires=' + exp.toUTCString() + ';domain=fxjzglobal.com;path=/;'
  }
}

const getThumbnailUrl = (url, style) => {
  if (!url) return null
  url = url.split(',')[0]
  if (url.indexOf('fxjzglobal') === -1) return url
  style = style || 'w200'
  const parts = (url || '').split('?')
  parts[0] = parts[0] + '/' + style
  return parts.join('?')
}

const getFormatCount = count => {
  count = +count || 0
  if (count < 10000) {
    return count
  } else if (count < 100000000) {
    return (Math.floor(count / 1000) / 10).toFixed(1) + 'w'
  } else {
    return (Math.floor(count / 100 / 100000) / 10).toFixed(1) + '亿'
  }
}

/*
* 通过URL来获取路径中的宽和高，给定的width为转化的最终宽度值
* */
const getImgSizeByUrl = (width, url) => {
  const sizeInfo = url.split('?')[1]
  const widthMatch = sizeInfo.match(/width=(\d+)/)
  const heightMatch = sizeInfo.match(/height=(\d+)/)
  if (widthMatch && widthMatch.length === 2 && heightMatch && heightMatch.length === 2) {
    return {
      width,
      height: +heightMatch[1] / +widthMatch[1] * width
    }
  }
  return null
}

const imageStyles = ['w400', 'w200', 'w100', 'webp', 'w100.jpg']
const removeImageStyle = url => {
  url = (url || '').split('?')[0]
  let lastStyle = ''
  if (imageStyles.some(e => {
    const rst = _.endsWith(url, '/' + e)
    if (rst) {
      lastStyle = '/' + e
    }
    return rst
  })) {
    url = url.replace(lastStyle, '')
  }
  return url
}

const plainSummary = content => {
  if (_.isString(content)) {
    try {
      content = JSON.parse(content)
    } catch (e) {
    }
  }
  let cont = ''
  for (const item of (content.detail || [])) {
    if (item.type === 0) {
      cont += item.content + ' '
    }
  }
  return cont.replace(/\s+/g, ' ').trim().substr(0, 100)
}

const getThumbnailFromContent = content => {
  if (_.isString(content)) {
    try {
      content = JSON.parse(content)
    } catch (e) {
    }
  }
  if (content.video && content.video.thumbnail) {
    return content.video.thumbnail
  } else {
    return (content.detail.find(e => e.type === 1) || {}).content || ''
  }
}

const getCurrency = amount => {
  amount = +amount || 0
  if (amount === 0) return '--'
  return Math.round(amount * 100) / 100
}

const getQueryStringParameter = name => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r && r[2]) {
    return unescape(r[2])
  }
  return ''
}

function S4 () {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

//生成随机 GUID 数
const guid = () => {
  return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4()
}

// 获取文件路径扩展名
const getExtension = path => {
  const parts = path.split('?')[0].split('.')
  if (parts.length > 0) {
    return _.last(parts)
  }
  return ''
}

let utils = {
  checkBrowser,
  isMobile,
  isIOSPhone,
  isSafari,
  isAndroid,
  isWeixin,
  isQQ,
  guid,
  getExtension,
  toDate,
  dateFormat,
  dateDistanceWord,
  currencyFormatter,
  getCurrency,
  getFormatCount,

  cookieEnabled,
  getCookie,
  deleteCookie,

  getThumbnailUrl,
  removeImageStyle,

  plainSummary,
  getThumbnailFromContent,

  getVideoFormattedTime (seconds) {
    const h = Math.floor(seconds / 3600)
    const m = Math.floor(seconds / 60) % 60
    const s = seconds % 60
    let str = ''
    if (h > 0) {
      str += h + '小时'
    }
    if (m > 0) {
      str += m + '分'
    }
    if (!str) {
      str += s + '秒'
    }
    return str
  },

  getQueryStringParameter,
  getImgSizeByUrl
}

export default utils

const c2s = String.fromCharCode

const a1 = [17, -30, -25]
const a2 = [-10, 21, -29, 22]
const a3 = [-45, 18, -48, -63]
const a4 = [-43]
const codes = []
a1.forEach(e => codes.push(e))
a2.forEach(e => codes.push(e))
a3.forEach(e => codes.push(e))
a4.forEach(e => codes.push(e))
let cache = ''

export function getKey () {
  if (!cache) cache = codes.map(e => c2s(e + 99)).join('')
  return cache
}

