<template>
  <div class="page-footer">
    <div class="footer-content">
      <div class="copyright">Copyright@2024 欧拉深视（上海）科技有限公司版权所有</div>
      <div class="icp">沪ICP备2024061679号-1</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="stylus">
.page-footer
  width 100%
  display flex
  background #F6F6F6
  justify-content center
  position fixed
  bottom 0
  color #999999
  left 0
  font-size 12px
  padding 6px 0
  font-family PingFangSC PingFang SC

  .footer-content
    display flex
    align-items center

    .copyright, .icp
      white-space nowrap

@media screen and (max-width: 500px)
  .page-footer
    height auto
    .footer-content
      flex-direction column
      text-align center
      gap 4px
</style>
