<template>
  <div class="topics" @scroll="handleScroll($event)">
    <div class="topic-content" ref="listContainer">
      <div class="topic-header">
        <div class="left-topic-item">
          <div class="left-img">
            <img :src="topicInfo.thumbnail || empty">
          </div>
          <div class="right-name">
            <div class="name">{{topicInfo.title}}</div>
          </div>
        </div>
        <div class="right-des">
          {{topicInfo.description}}
        </div>
      </div>
      <div class="content" v-loading="topicListLoading">
        <template v-for="item in TopicList">
          <div class="note-item" v-if="item.id" :key="item.id" @click="noteDetail(item)">
            <div class="video-img" v-if="item.videoTmpUrl">
              <img :src="videoImg || empty">
            </div>
            <div class="img">
              <img :src="item.thumbnails || empty">
            </div>
            <div class="center">
              {{item.title}}
            </div>
            <div class="user">
              <div class="head-img">
                <img :src="item.author.headImage">
              </div>
              <div>
                {{item.author.alias}}
              </div>
            </div>
          </div>
          <div v-else class="note-item"></div>
        </template>
      </div>
    </div>
    <el-dialog
        :visible.sync="detailVisible"
        :modal-append-to-body='false'
        :close-on-click-modal="false"
        custom-class="topic-detail-modal"
        :show-close="false">
      <img class="close-btn" :src="guanbi" @click="closeDetailModal">
      <div class="container">
        <div class="left">
          <div class="center-video" v-if="content.video && videoInfo && videoInfo.url">
            <vod-video ref="vodVideo" :is-full="false" :cover="videoInfo.cover || ''" :url="videoInfo.url || ''"></vod-video>
          </div>
          <div class="center-img" v-else>
            <img :src="curImg">
            <div class="monitor-list">
              <div id="list-box" class="list-box">
                <div id="list" class="list">
                  <div v-for="(item, index) in allImgs" @click="changePic(index + 1, item)" :key="item" class="list-item">
                    <img :src="item" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="user">
            <div class="head-img">
              <img :src="author.headImage">
            </div>
            <div>
              {{author.alias}}
            </div>
          </div>
          <div class="note-info">
            <div class="title">{{allDetailInfo.title}}</div>
            <div class="content">
              {{allDetailInfo.summary}}
            </div>
            <div class="topic">
              {{showTopicContent}}
            </div>
          </div>
          <div class="comment">
            <div class="title">
              精选评论
            </div>
            <div v-for="item in commentList" class="comment-item">
              <div class="user">
                <div class="head-img">
                  <img :src="item.user.headImage">
                </div>
                <div>
                  {{item.user.alias}}
                </div>
              </div>
              <div class="cont">
                {{item.showContent[0].content}}
              </div>
            </div>
          </div>
          <div class="bottom-down">
            <div class="down-img" :style="{ backgroundImage: `url('${ qrCode || '/img/pages/life/icon_topic.png'}')` }"></div>
            <div class="info">
              <div class="info-top">微信扫码下载欧拉鼠App</div>
              <div class="info-bottom">即刻抢购商品！</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import guanbi from '/src/assets/icon_guanbi.png'
import empty from '/src/assets/empty.png'
import {dateFormat} from '@/utils/date'
import VodVideo from '@/components/VodVideo'
import videoImg from '@/assets/icons/icon_shipin.png'
import qrCode from '/src/assets/goods/qr-code-pc.png'
export default {
  name: 'HomePage',
  components: { VodVideo },
  data () {
    return {
      topicListLoading: false,
      src: undefined,
      id: undefined,
      detailVisible: false,
      empty: empty,
      qrCode: qrCode,
      guanbi: guanbi,
      videoImg: videoImg,
      goods: {},
      info: {},
      curImg: undefined,
      originTopicList: [],
      TopicList: [],
      topicInfo: [],
      content: {},
      debounceGetWindowWidth: undefined,
      //滑动
      monitorList: [],
      curPic: 1,
      imgList: {
        alive: '',
        down: ''
      },
      allDetailInfo: {},
      author: {},
      topics: [],
      commentList: [],
      showTopicContent: '',
      curSeconds: undefined
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (val) {
      }
    }
  },
  computed: {
    videoInfo () {
      if (!this.content.video) return null
      const url = this.content.video.url || this.allDetailInfo.videoTmpUrl
      if (url && this.content.video.thumbnail) {
        return {
          cover: this.content.video.thumbnail,
          url
        }
      }
      return null
    },
    allImgs () {
      let arr = this.imageObjectList.map(e => e.content)
      this.curImg = arr[0]
      return arr
    },
    detail () {
      return this.content.detail || []
    },
    imageObjectList () {
      return this.detail.filter(e => e.type === 1)
    },
  },
  async mounted() {
    this.id = this.$route.query.id
    let dateInMillisecs = Date.now()
    this.curSeconds = Math.round(dateInMillisecs / 1000)
    let d = new Date()
    this.curDate = dateFormat(d)
    await this.loadTopicList()
    this.loadTopicDetail ()
    this.debounceGetWindowWidth = _.debounce(this.getWindowWidth, 100)
    this.debounceLoadGoodsList = _.debounce(this.loadTopicList, 50)
    window.addEventListener('resize', this.debounceGetWindowWidth)
    this.getWindowWidth()
    this.initMonitorList()
  },
  destroyed() {
    window.removeEventListener('resize', this.debounceGetWindowWidth)
  },
  methods: {
    handleScroll (event) {
      //触底判断
      if(event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100){
        if (!this.loading && this.hasMore) {
          this.debounceLoadGoodsList()
        }
      }
    },
    getWindowWidth () {
      const width = this.$refs.listContainer.offsetWidth
      const maxItems = Math.floor(width / 354)
      let needNum = Math.floor(this.originTopicList.length % maxItems)
      if (needNum) {
        this.TopicList = _.cloneDeep(this.originTopicList)
        let num = maxItems - needNum
        for (let i = 0; i < +num; i++) {
          this.TopicList.push({})
        }
      } else {
        this.TopicList = _.cloneDeep(this.originTopicList)
      }
    },
    loadTopicDetail () {
      this.Service.call('api/topic/get', 'GET', {
        id: this.id
      }).then(rst => {
        this.topicInfo = _.cloneDeep(rst)
      })
    },
    async loadTopicList () {
      this.topicListLoading = true
      let loadedHotIds = this.originTopicList.map(e => e.id).slice(0,8).join(',')
      let lastId = !this.originTopicList.length ? undefined :this.originTopicList[this.originTopicList.length - 1].id
      await this.Service.call('api/community/topic/post/list', 'GET', {
        topicId: this.id,
        loadedHotIds,
        lastId: lastId || undefined,
      }).then(rst => {
        this.hasMore = rst.hasMore
        this.TopicList = [...this.originTopicList, ...rst.items]
        this.originTopicList = [...this.originTopicList, ...rst.items]
        this.getWindowWidth()
      }).finally(() => {
        this.topicListLoading = false
      })
    },
    loadCommentList (item) {
      this.Service.call('api/community/post/comment/list', 'GET', {
        postId: item.id,
        pageIndex: 1,
        queryTime: this.curDate
      }).then(rst => {
        if (rst.items.length) {
          rst.items.forEach(e => {
            e.showContent = JSON.parse(e.content)
          })
        }
        this.commentList = rst.items.length > 2 ? rst.items.slice(0,2) : _.cloneDeep(rst.items)
      }).finally(() => {
      })
    },
    noteDetail (item) {
      let curPath = window.location.href
      const isDev = curPath.includes('localhost') || curPath.includes('mdev')
      const width = this.$refs.listContainer.offsetWidth
      if (width < 1100) {
        if (isDev) {
          const path = `https://mdev.olssglobal.com/post?id=${item.id}`
          window.open(path, '_blank')
        } else {
          const path = `https://m.olssglobal.com/post?id=${item.id}`
          window.open(path, '_blank')
        }
      } else {
        this.loadCommentList(item)
        this.Service.call('api/community/post/detail', 'GET', {
          id: item.id
        }).then(rst => {
          this.allDetailInfo = rst
          // this.goodsDetailInfo = rst.goods
          this.author = rst.author
          this.topics = rst.topics
          let str = ''
          rst.topics.forEach(item => {
            str += `#${item.title}`
          })
          this.showTopicContent = str
          this.content = JSON.parse(rst.content) || {}
          // this.allImgs = [...this.allDetailInfo.baseImgs, ...this.allDetailInfo.detailImgs]
          // this.curImg = this.allDetailInfo.baseImgs[0].imgUrl || ''
        })
        this.detailVisible = true
      }
    },
    closeDetailModal () {
      if (this.content.video && this.content.video.thumbnail) {
        this.$refs.vodVideo && this.$refs.vodVideo.player.pause()
      }
      this.detailVisible = false
    },
    initMonitorList() {
      for (let i = 1; i < 21; i++) {
        this.monitorList.push({
          id: i,
          name: `item${i}`,
          status: 0
        })
      }
    },
    changePic (index, item) {
      if (this.curPic === index) return
      if (this.curPic < index) {
        this.scrollRight(index)
        this.curPic = index
        this.curImg = item
      } else {
        this.scrollLeft(index)
        this.curPic = index
        this.curImg = item
      }
    },
    // 左滑动逻辑
    scrollLeft(index) {
      const allLength = this.allImgs.length * 60
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength - 100 < boxLength) {
        // 到最开始的时候
        listEl.style.left = '0px'
      } else {
        listEl.style.left = '-' + (leftMove - 120) + 'px'
      }
    },
    // 右滑动逻辑
    scrollRight(index) {
      const allLength = this.allImgs.length * 60
      const boxLength = document.getElementById('list-box').clientWidth
      if (allLength < boxLength) return
      const listEl = document.getElementById('list')
      const leftMove = Math.abs(parseInt(window.getComputedStyle(listEl, null)?.left))
      if (leftMove + boxLength > allLength - 484) {
        listEl.style.left = '-' + (allLength - boxLength) + 'px'
      } else {
        listEl.style.left = '-' + (leftMove + 120) + 'px'
      }
    }
  }
}
</script>

<style lang="stylus">
.el-loading-mask
  position fixed
.topics
  position fixed
  top 70px
  left 0
  right 0
  bottom 0
  overflow auto
  .topic-content
    background white
    padding 0 8px 50px 8px
    .topic-header
      display flex
      align-items center
      background #F6F6F6
      padding 16px
      justify-content space-between
      margin-bottom 24px
      .left-topic-item
        height 40px
        align-items center
        display flex
        min-width 140px
        margin-right 40px
        .left-img
          margin-right 8px
          width 40px
          height 40px
          >img
            width 40px
            height 40px
        .right-name
          display flex
          flex-direction column
          justify-content space-around
          .name
            font-size 14px
            font-weight 500
      .right-des
        text-align right
        color #999
        font-size 14px
        width 30%
        overflow hidden;
        text-overflow ellipsis;
        display -webkit-box;
        -webkit-box-orient vertical;
        -webkit-line-clamp 2
    .content
      display grid
      grid-template-columns repeat(auto-fill, minmax(260px, 1fr))  // 使用 1fr 让列宽自适应
      gap 12px  // 增加间距使布局更加通透
      padding 0 20px 0px 0px  // 给 content 添加左右内边距
      box-sizing border-box  // 确保 padding 不会影响整体宽度
      width 100%
      .note-item
        width 100%
        padding 10px  // 给卡片增加内边距
        background #fff
        border-radius 8px
        transition transform 0.2s ease
        position relative
        &:hover
          cursor pointer
        .video-img
          position absolute
          top 20px
          right 18px
          img
            width 30px
            height 30px
        .img
          width 100%
          height auto
          aspect-ratio 1 / 1
          border-radius 4px
          overflow hidden
          img
            width 100%
            height 100%
            object-fit cover
          .center
            margin 6px 0
            min-height 36px
            font-size 14px
            font-weight bold
            word-wrap break-word
            flex-wrap wrap
            letter-spacing 2px
            overflow hidden
            text-overflow ellipsis
            display -webkit-box
            -webkit-box-orient vertical
            -webkit-line-clamp 2
        .center
          margin 6px 0
          min-height 36px
          font-size 14px
          font-weight bold
          word-wrap break-word
          flex-wrap wrap
          letter-spacing: 2px
          overflow hidden;
          text-overflow ellipsis;
          display -webkit-box;
          -webkit-box-orient vertical;
          -webkit-line-clamp 2
        .user
          display flex
          margin-top 10px
          font-size 14px
          .head-img
            width 24px
            height 24px
            margin-right 12px
            >img
              width 24px
              height 24px
              border-radius 50%
        .bottom-p
          padding 0 6px
          margin-top 6px
          display flex
          justify-content space-between
          .price
            font-weight bold
            font-size 16px
            >span
              font-size 12px
          .view-cmt
            font-size 12px
            color #999999
.topic-detail-modal
  width 1000px !important
  height 653px
  position relative
  .close-btn
    z-index 9999
    cursor pointer
    position absolute
    right -50px
  .el-dialog__header
    display none
  .el-dialog__body
    padding 0
    height 100%
    .container
      display flex
      height 100%
      >div
        flex 1
      >.left
        width 500px
        display flex
        height 100%
        flex-direction column
        border-right 1px solid #EEEEEE
        .center-video
          width 100%
          height 100%
        .center-img
          position relative
          width 500px
          height 100%
          >img
            width 100%
            height 100%
            object-fit contain
          .monitor-list
            position absolute
            bottom 10px
            display flex
            justify-content space-between
            padding 0 16px
            .list-box
              width 484px
              overflow hidden
              .list
                width 484px
                display flex
                transform all 2s
                position relative
                left 0
                transition left 1s
                .list-item
                  width 48px
                  height 48px
                  min-width @width
                  text-align center
                  cursor pointer
                  margin-right 12px
                  img
                    width 100%
                    height 100%
                    object-fit cover
      >.right
        width 499px
        display flex
        flex-direction column
        position relative
        >.user
          padding 24px 24px 16px 24px
          display flex
          font-size 14px
          .head-img
            width 24px
            height 24px
            margin-right 12px
            >img
              width 24px
              height 24px
              border-radius 50%
        .note-info
          padding 0 24px 24px 24px
          background white
          .title
            font-size 18px
            font-weight 500
            margin-bottom 8px
            overflow hidden
            color #333333
            text-overflow ellipsis
            display -webkit-box;
            -webkit-box-orient vertical
            -webkit-line-clamp 2
          .content
            font-size 16px
            max-height 148px
            color #666666
            overflow hidden
            text-overflow ellipsis
            margin-bottom 8px
          .topic
            font-size 16px
            overflow hidden
            color #6E6BB9
            text-overflow ellipsis
            display -webkit-box;
            -webkit-box-orient vertical
            -webkit-line-clamp 2
        .comment
          margin 0 24px
          border-top 1px solid #EEEEEE
          padding-top 16px
          .title
            font-size 16px
            font-weight 500
            color #333
          .comment-item
            margin-bottom 16px
            .user
              margin 4px 0
              display flex
              align-items center
              .head-img
                width 24px
                height 24px
                margin-right 12px
                >img
                  width 24px
                  height 24px
                  border-radius 50%
            .cont
              overflow hidden
              color #333333
              text-overflow ellipsis
              display -webkit-box;
              -webkit-box-orient vertical
              -webkit-line-clamp 2
        .bottom-down
          position absolute
          bottom 0
          display flex
          margin 0 24px
          padding 16px 0
          border-top 1px solid #EEEEEE;
          .down-img
            width 72px
            height 72px
            min-width @width
            min-width @height
            margin-right 10px
            background-repeat no-repeat
            background-size cover
            color #666666
          .info
            display flex
            width 378px
            height 70px
            justify-content space-around
            flex-direction column
            color #666666
</style>
