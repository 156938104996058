const href = window.location.href
const host = window.location.host
let isProd = host === 'www.olssglobal.com' || host === 'olssglobal.com'
let baseUrl = isProd ? '/' : host.indexOf('dev') > -1 && href.indexOf('https') > -1 ? 'https://dev.olssglobal.com/' : 'http://dev.olssglobal.com/'
const appUrl = {
  ANDROID: `https://sj.qq.com/appdetail/com.fxjzglobalapp.jiazhiquan`,
  IOS: 'https://apps.apple.com/cn/app/id1638259152?mt=8',
  YING_YONG_BAO: 'https://sj.qq.com/appdetail/com.fxjzglobalapp.jiazhiquan'
}
export default {
  IS_PROD: isProd,
  BASE_URL: baseUrl,
  APP_URL: appUrl
}
